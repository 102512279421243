<template>
  <v-container fluid>
    <v-row>
      <v-col lg="8" md="8" sm="8">
        <v-select
          v-model="selected_month"
          :items="months"
          outlined
          dense
          label="Select Month"
          clearable
          @click:clear="selected_month = null"
        ></v-select>
      </v-col>
      <v-col lg="4" md="4" sm="4">
        <v-btn color="primary" :disabled="!selected_month"
          >Download <v-icon small right>mdi-download</v-icon></v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "ExportSingleMonthCard",
  props: ["months"],
  data() {
    return {
      selected_month: null,
    };
  },
};
</script>